<template>
  <div class="storyPrice">
    <div class="row align-items-center justify-content-between">
      <div class="col-xxl-5 col-md-6">
        <base-alerts />
        <h1>{{ title }}</h1>
        <div v-if="influencerAccounts[0].priceRecommendations.story">
          <div>
            <base-touch-spin
              sign="$"
              v-model="storyPrice.price"
              :price="influencerAccounts[0].priceRecommendations.story.value"
            />
          </div>
          <p class="subTitle">
            {{ subTitle }}
          </p>
          <b-form-checkbox
            v-model="storyPrice.priceNegotiable"
            :value="true"
            :unchecked-value="false"
          >
            My price is negotiable
          </b-form-checkbox>

          <div class="keywords">
            <h5>Please select some keywords which best define your audience</h5>
          </div>
          <base-multi-select
            :list="userTags"
            :multiple="true"
            label="tags"
            id="id"
            v-model="selectedTag"
            @input="getSelectedTag"
          >
          </base-multi-select>

          <div class="button-row">
            <button
              type="submit"
              class="btn btn-primary large"
              :class="processing ? 'processing' : ''"
              @click="onSubmit"
            >
              {{ btnText }}
            </button>
          </div>
        </div>
        <div v-if="this.$apollo.error">
          {{ this.$apollo.error }}
        </div>
      </div>
      <div class="col-xxl-7 col-md-6">
        <div class="image">
          <img
            src="@/assets/images/story-price.png"
            class="img-fluid"
            alt="story-price-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UPDATE_STORY_PRICE, UPDATE_USER_TAGS } from "@/graphql/user/mutations";
import { USERS_TAGS } from "@/graphql/user/query";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      title: "Modify Story Price",
      subTitle: "We recommend choosing this price ",
      type: "instagram",
      processing: false,
      btnText: "Save",
      storyPrice: {
        account_id: 0,
        price: null,
        priceNegotiable: true,
      },
      userTags: [],
      selectedTag: [],
      norecord: false,
    };
  },
  computed: {
    ...mapGetters("mediaAccounts", ["influencerAccounts"]),
  },
  apollo: {
    userTags: {
      query: USERS_TAGS,
    },
  },
  methods: {
    ...mapActions("mediaAccounts", ["setMediaAccount"]),
    onSubmit() {
      this.updateStoryPrice();
    },
    async updateStoryPrice() {
      this.processing = true;
      this.btnText = "Saving...";
      if (this.influencerAccounts.length > 0) {
        this.storyPrice.account_id = this.influencerAccounts[0].id;
        if (this.storyPrice.price == null) {
          this.storyPrice.price =
            this.influencerAccounts[0].priceRecommendations.story.value;
        }
      }
      this.storyPrice.price = String(this.storyPrice.price);
      await this.$apollo
        .mutate({
          mutation: UPDATE_STORY_PRICE,
          variables: this.storyPrice,
        })
        .then(async (data) => {
          if (data) {
            if (
              data.data.updateMediaAccount.state == "added" ||
              data.data.updateMediaAccount.state == "updated"
            ) {
              await this.updateUserTag();
              await this.setMediaAccount();
              this.$router.push("profile-photo");
              this.processing = false;
              this.btnText = "Save";
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
          this.processing = false;
          this.btnText = "Save";
        });
    },
    getSelectedTag() {
      this.selectedTag.map((items) => items.tags).toString();
    },
    async updateUserTag() {
      let newTags = this.selectedTag.map((items) => items.id);
      await this.$apollo
        .mutate({
          mutation: UPDATE_USER_TAGS,
          variables: {
            id: newTags,
          },
        })
        .then(async (data) => {
          if (data) {
            if (
              data.data.updateUsersTags.state == "added" ||
              data.data.updateUsersTags.state == "updated"
            ) {
              this.btnText = "Save";
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
          this.processing = false;
          this.btnText = "Save";
        });
    },
  },
};
</script>
<style lang="scss">
.LoginRegister {
  .contentWrapper {
    padding-top: 0 !important;
  }
  .storyPrice {
    .quantityToggle {
      input {
        width: 135px;
        font-size: rem(52px);
        font-weight: 900;
        height: 45px;
        color: var(--primary);
        @media screen and (max-width: 991px) {
          width: 220px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      button {
        width: 42px;
        height: 42px;
        min-width: 42px;
        border: 5px solid var(--primary);
        &.decrement {
          &:before {
            width: 14px;
            height: 16px;
          }
        }
        &.increament {
          &:before {
            width: 18px;
            height: 21px;
          }
        }
      }
    }
    @media screen and (max-width: 1850px) {
      .image {
        img {
          max-width: 440px;
        }
      }
    }
    @media screen and (max-width: 1400px) {
      .image {
        img {
          max-width: 385px;
        }
      }
    }
    @media screen and (max-width: 991px) {
      .image {
        img {
          max-width: 100%;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .button-row {
        justify-content: start !important;
      }
    }
  }
  .custom-control {
    &.custom-checkbox {
      margin-bottom: 1rem;
    }
  }
  .v-select {
    .v-dropdown-container {
      background: none !important;
    }
  }
  .keywords {
    margin-top: 50px;
  }
}
</style>
