import gql from "graphql-tag";

// export const LOGIN_USER = gql`
//   mutation createSession($email: String!, $password: String!) {
//     createSession(email: $email, password: $password) {
//       token
//     }
//   }
// `;

// export const REGISTER_USER = gql`
//   mutation registerUser($email: String!, $password: String!, $type: Int!) {
//     registerUser(email: $email, password: $password, type: $type) {
//       token
//     }
//   }
// `;

export const MEDIA_ACCOUNT = gql`
  mutation updateMediaAccount(
    $account_id: Int!
    $access_token: String
    $refresh_token: String
    $provider_user_id: String
  ) {
    updateMediaAccount(
      account_id: $account_id
      access_token: $access_token
      refresh_token: $refresh_token
      provider_user_id: $provider_user_id
    ) {
      state
    }
  }
`;

export const UPDATE_STORY_PRICE = gql`
  mutation updateMediaAccount(
    $account_id: Int!
    $price: String
    $priceNegotiable: Boolean
  ) {
    updateMediaAccount(
      account_id: $account_id
      price: $price
      priceNegotiable: $priceNegotiable
    ) {
      state
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String, $retype: String) {
    updatePassword(password: $password, retype: $retype) {
      state
      msg
    }
  }
`;
export const CAMPAIGN_DELIVERY = gql`
  mutation campaignDelivery($image: Upload!, $idCampaign: Int!) {
    campaignDelivery(image: $image, idCampaign: $idCampaign) {
      id
      value
    }
  }
`;

export const CAMPAIGN_SUBSCRIBE = gql`
  mutation campaignSubscribe($idCampaign: Int!) {
    campaignSubscribe(idCampaign: $idCampaign) {
      state
      msg
    }
  }
`;

export const OFFER_SUBSCRIBE = gql`
  mutation offerSubscribe($idOffer: Int!) {
    offerSubscribe(idOffer: $idOffer) {
      state
    }
  }
`;

export const REMOVE_DELIVERY = gql`
  mutation removeDelivery($id: Int!) {
    removeDelivery(id: $id) {
      state
    }
  }
`;

export const FINISH_DELIVERY = gql`
  mutation campaignDeliveryComplete($idCampaign: Int!) {
    campaignDeliveryComplete(idCampaign: $idCampaign) {
      state
    }
  }
`;
export const UPDATE_WITHDRAWL_METHOD = gql`
  mutation updateWithdrawlMethod($type: String, $data: String) {
    updateWithdrawlMethod(type: $type, data: $data) {
      state
    }
  }
`;
export const UPDATE_USER_TAGS = gql`
  mutation updateUsersTags($id: [Int]) {
    updateUsersTags(id: $id) {
      state
    }
  }
`;
